import instance from "@/api/http";

export default {
  sendRestoreLink(email: string) {
    return instance.post('restorePass', { email })
  },
  changePassword(linkId: string, password: string) {
    return instance.patch('restorePass', { linkId, password })
  },
  changePasswordSelf(oldPassword: string, newPassword: string) {
    return instance.patch('user/password', { oldPassword, newPassword })
  },
}
