import { gps, sortOrderEnum } from "@/types/main";

export enum devicesPagesEnum {
  list = 'list',
  map = 'map'
}

export enum devicesTabsEnum {
  devices = 'Устройства',
  cameraGroups = 'Группы камер'
}

export enum creationCameraEnum {
  LAN = 'LAN',
  WiFi = 'WiFi',
  RTSP = 'RTSP',
  MAC = 'MAC'
}

export enum cameraTypeEnum {
  transmitter_cam = 'transmitter_cam',
  pnp_rtsp_cam = 'pnp_rtsp_cam',
  pnp_cam = 'pnp_cam',
  pnp_lan_cam = 'pnp_lan_cam'
}

export enum cameraPositionEnum {
  inner = 'Внутренняя',
  outside = 'Внешняя'
}

export type transmitterCreationData = {
  name?: string,
  serialNumber: string,
  organisationName: string,
  description?: string,
  gps?: gps,
  geoAddress?: string,
}

export type transmitterUpdateData = Omit<transmitterCreationData, 'organisationName'>

export type cameraCreationData = {
  cameraName: string,
  organisationName: string,
  cameraType: cameraTypeEnum,
  rtsp?: string,
  archiveDepth?: number,
  macAddress?: string,
  transmitterSerialNumber?: string,
  description?: string,
  viewDescription?: string,
  isInner?: boolean,
  serviceTypeId?: number,
  objectTypeId?: string,
  gps?: gps,
  geoAddress?: string
}

export type cameraUpdateData = Omit<
  cameraCreationData, 'cameraType' | 'rtsp' | 'macAddress' | 'transmitterSerialNumber' | 'organisationName'
> & {
  sourceId: string,
  organisationName?: string
}

export type camerasGroupCreationData = {
  groupName: string,
  description?: string,
  cameraIds: Array<string>,
  organisationName?: string
}

export type camerasGroupUpdateData = Omit<camerasGroupCreationData, 'groupName'> & {
  id: string,
  groupName?: string
}

export enum devicesSidebarType {
  transmitter,
  camera,
  camerasGroup
}

export type cameraRequestParams = {
  organisationNames?: string,
  district?: string,
  cameraTypes?: string,
  models?: string,
  status?: string
  connection?: string
  echdStates?: string
  sourceIds?: string
  limit?: number
  offset?: number
  search?: string
}

export type transmittersRequestParams = {
  organisationNames?: string,
  statuses?: string
}

export type camerasGroupsRequestParams = {
  organisationNames?: string,
  search?: string
}

export type commentsRequestParams = {
  sourceId?: string,
  groupByDate?: boolean
  limit?: number
  offset?: number
  sortBy: string,
  sortOrder: sortOrderEnum,
}

export enum streamEnum {
  live = 'live',
  arch = 'arch',
}

type playerLive = {
  action: streamEnum.live
}

type playerArch = {
  action: streamEnum.arch
  from: string
  to: string
}

export type playerParams = playerArch | playerLive

export type CameraMarkerType = {
  id: string;
  lngLat: [number, number];
  isOnline: boolean;
};

export type CamerasMarkersGrouped = {
  cameras: Array<CameraMarkerType>;
  cameraGroupsByGps: Array<Array<CameraMarkerType>>;
};

export type positionPTZ = {
  id: string
  sourceID: string
  name: string
  x: number
  y: number
  zoom: number
  createdAt: string
  isHome: boolean
}
