import instance from "@/api/mapbox";
import { initialCoordinates } from "@/consts/map";

export default {
  forwardGeocoding(text: string) {
    const params = {
      language: 'ru',
      proximity: [initialCoordinates.longitude, initialCoordinates.latitude],
      country: 'UZB'
    }
    return instance.get(`mapbox.places/${text}.json`, { params })
  },
  reverseGeocoding(longitude: number, latitude: number) {
    const params = { language: 'ru' }
    return instance.get(`mapbox.places/${longitude},${latitude}.json`, { params })
  }
}
