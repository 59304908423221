import { defineStore } from 'pinia'
import api from "@/api/api";

export const useMapStore = defineStore('map', {
  actions: {
    getAddress(longitude: number, latitude: number) {
      return api.map.reverseGeocoding(longitude, latitude)
    },
    getCoordinates(text: string) {
      return api.map.forwardGeocoding(text)
    }
  }
})
