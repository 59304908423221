import { defineStore } from 'pinia'
import { useSocket } from "@/api/websocket";
import {
  cameraStatusNotification,
  notificationEnum,
  userNotification,
  websocketNotification
} from "@/types/notifications";
import { useNotificationsStore } from "@/store/notifications/notifications";
import { useAppNotificationsStore } from "@/store/appNotifications";
import { useUserStore } from "@/store/user";
import getFullName from "@/utils/getFullName";
import { useAuthStore } from "@/store/auth";
import { useCamerasStore } from "@/store/devices/cameras";
import { rootWebsocketState } from "@/types/store";

export const useSocketStore = defineStore('socket', {
  state: (): rootWebsocketState => ({
    socket: null,
    socketEvents: []
  }),

  actions: {
    initSocket() {
      const userStore = useUserStore()
      const authStore = useAuthStore()

      try {
        this.socket = useSocket(userStore.userId)
        this.socket.onopen = () => {
          console.log('Successfully connected to the echo websocket server.')
          this.socketEvents.forEach((event) => {
            event()
          })
          this.socketEvents = []
        }
        this.socket.onmessage = (event) => {
          const data = JSON.parse(event.data)
          this.processNotification(data.data as userNotification)
        }
        this.socket.onclose = () => {
          if (authStore.isAuth) {
            setTimeout(() => {
              this.initSocket()
            }, 2000)
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    closeSocket() {
      if (this.socket) {
        this.socket.close()
        this.socket = null
      }
    },
    addListener(event: () => void) {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        event()
      } else {
        this.socketEvents.push(event)
      }
    },
    subscribeChannel(channel: string) {
      if (this.socket) {
        this.socket.send(JSON.stringify({ channel, action: 'sub' }))
      }
    },
    unsubscribeChannel(channel: string) {
      if (this.socket) {
        this.socket.send(JSON.stringify({ channel, action: 'unsub' }))
      }
    },
    processCameraNotification(notification: cameraStatusNotification) {
      const camerasStore = useCamerasStore()
      camerasStore.changeOnlineStatus(notification.cameraId, notification.isOnline)
    },
    processUserNotification(notification: userNotification) {
      const notificationStore = useNotificationsStore()
      const appNotificationStore = useAppNotificationsStore()

      let title = ''
      if ([notificationEnum.stp_all, notificationEnum.stp_direct, notificationEnum.stp_org].includes(notification.notificationType)) {
        title = 'Служба технической поддержки'
      } else {
        title = getFullName({
          firstName: notification.author.firstName,
          lastName: notification.author.lastName,
          patronymic: notification.author.patronymic
        })
      }
      notificationStore.addNotification(notification)
      appNotificationStore.notify({ title, text: notification.message }, 7)
    },
    processNotification(notification: websocketNotification) {
      switch (notification.notificationType) {
        case notificationEnum.media_root_status:
          this.processCameraNotification(notification as cameraStatusNotification)
          break
        default:
          this.processUserNotification(notification as userNotification)
      }
    }
  }
})
