export type optionsType = {
  bitrate: number
  bitrateMode: string
  resolution: string
  fps: number
  overlay?: string
}

export enum categoryEnum {
  all = 'all',
  cameras = 'cameras',
  groups = 'groups'
}

export enum categoryRusEnum {
  all = 'Все камеры',
  cameras = 'Одна камера',
  groups = 'Группы камер'
}

export enum actionEnum {
  reboot = 'reboot',
  clear_options = 'clear_options',
  change_options = 'change_options'
}

export enum deviceType {
  individual = 'individual',
  group = 'group',
}

export type allSettingsRequestParams = {
  action: actionEnum
  options?: Partial<Omit<optionsType, 'overlay'>>
}

export type settingsRequestParams = {
  type: deviceType
  ids: Array<string>
  command: {
    action: actionEnum,
    options?: Partial<optionsType>
  }
}
