import { defineStore } from 'pinia'
import { rootUserState } from "@/types/store";
import { user, userRolesEnum } from "@/types/main";

export const useUserStore = defineStore('user', {
  state: (): rootUserState => ({
    userRole: localStorage.getItem('userRole') as userRolesEnum,
    userOrganisation: localStorage.getItem('userOrganisation') as string,
    userFirstName: localStorage.getItem('userFirstName') as string,
    userLastName: localStorage.getItem('userLastName') as string,
    userPatronymic: localStorage.getItem('userPatronymic') as string,
    userId: localStorage.getItem('userId') as string,
  }),

  actions: {
    setUserData(user: user) {
      localStorage.setItem('userRole', user.role)
      localStorage.setItem('userOrganisation', user.organisationName)
      localStorage.setItem('userFirstName', user.firstName)
      localStorage.setItem('userLastName', user.lastName)
      localStorage.setItem('userPatronymic', user.patronymic)
      localStorage.setItem('userId', user.id)
      this.userRole = user.role
      this.userOrganisation = user.organisationName
      this.userFirstName = user.firstName
      this.userLastName = user.lastName
      this.userId = user.id
    }
  },
  getters: {
    isStp: (state) => state.userRole === userRolesEnum.stp,
    isVendor: (state) => state.userRole === userRolesEnum.vendor,
    isAdmin: (state) => state.userRole === userRolesEnum.admin,
    isSuperAdmin: (state) => state.userRole === userRolesEnum.super_admin,
    isUser: (state) => state.userRole === userRolesEnum.user,
  },
})
