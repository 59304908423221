import { defineStore } from 'pinia'
import api from '@/api/api'
import { rootAuthState } from "@/types/store";
import { useUserStore } from "@/store/user";

export const useAuthStore = defineStore('auth', {
  state: (): rootAuthState => ({
    sessionId: localStorage.getItem('sessionId')
  }),

  actions: {
    onLogin(login: string, password: string, agreementAccepted?: boolean) {
      const userStore = useUserStore()

      return api.auth.login(login, password, agreementAccepted)
        .then((res) => {
          const { sessionId, user } = res.data.data
          userStore.setUserData(user)
          this.sessionId = sessionId
          localStorage.setItem('sessionId', sessionId)
        })
    },
    onLogout() {
      return api.auth.logout()
        .then(() => {
          localStorage.removeItem('sessionId')
          localStorage.removeItem('userRole')
          location.reload()
        })
    }
  },

  getters: {
    isAuth: (state) => !!state.sessionId
  }
})
