import instance from "@/api/http";
import { playerParams } from "@/types/devices";

export default {
  initPlayer(id: string, params: playerParams) {
    return instance.get('camera/player', { params: { sourceId: id, ...params } })
  },
  getScreenshot(sourceId: string, timestamp: string) {
    return instance.get(`player/screenshot/${sourceId}`, { params: { timestamp }, responseType: 'blob' })
  }
}
