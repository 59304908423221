export enum notificationSentType {
  stp_all = 'stp_all',
  stp_org = 'stp_org',
  stp_direct = 'stp_direct'
}

export enum notificationEnum {
  comment_from_user = 'comment_from_user',
  comment_from_stp = 'comment_from_stp',
  stp_org = 'stp_org',
  stp_all = 'stp_all',
  stp_direct = 'stp_direct',
  camera_status = 'camera_status',
  delete_camera = 'delete_camera',
  create_organisation = 'create_organisation',
  media_root_status = 'mediaRootStatus'
}

export type notificationRequest = {
  message: string
  notificationType: notificationSentType
  organisationName?: string
  userIds?: Array<string>
}

export type userNotification = {
  "notificationId": string
  "author": {
    "id": string
    "login": string
    "role": string
    "firstName": string
    "lastName": string
    "patronymic": string
    "organisationName": string
    "createdBy": {
      "id": string
      "firstName": string
      "lastName": string
      "patronymic": string
    },
    "createdAt": string
    "modifiedAt": string
    "notificationsOn": boolean
  },
  "notificationType": notificationEnum
  "cameraName": string
  "message": string
  "isSeen": boolean
  "cameraId": string
  "organisationId": string
  "createdAt": string
  "decisionAccepted": boolean
  "decisionRejected": boolean
}

export type cameraStatusNotification = {
  notificationType: notificationEnum
  cameraId: string
  isOnline: boolean
}

export type websocketNotification = userNotification | cameraStatusNotification

export enum sidebarNotificationType {
  notification,
  camera,
  organisation,
}
