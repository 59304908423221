export default (val: string) => {
  switch (val) {
    case 'base':
      return "Должен содержать маленькую и большую латинскую букву, цифру, спецсимвол ! # % & ' * , . : ; ? @ `"
    case 'minLength':
      return 'Минимальная длина 8 символов (12 для СТП)'
    case 'uniq':
      return 'Должен содержать минимум 5 уникальных символов'
    case 'together':
      return 'Не должно быть групп символов, расположенных рядом на клавиатуре'
    case 'same':
      return 'Не должно быть повторяющихся подряд более 2 раз символов'
    case 'sameGroup':
      return 'Не должно быть повторяющихся 2 и более раз групп символов'
    default:
      return ''
  }
}
