export default (data: Blob, filename: string, MIMEType: string) => {
  const blob = new Blob(
    [data],
    { type: MIMEType },
  );
  const href = URL.createObjectURL(blob);

  const a = Object.assign(document.createElement('a'), {
    href, style: 'display: none', download: filename
  })
  document.body.appendChild(a)
  a.click()
  URL.revokeObjectURL(href)
  a.remove()
}
