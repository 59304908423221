import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw
} from 'vue-router';
import { useAuthStore } from "@/store/auth";
import { useDevicesStore } from "@/store/devices/devices";
import { devicesPagesEnum } from "@/types/devices";
import { userRolesEnum } from "@/types/main";
import { useUserStore } from "@/store/user";
import { useMenuStore } from "@/store/menu";
import { useLoadingStore } from "@/store/loading";

const LoginView = () => import('@/views/LoginView.vue');
const ProfileView = () => import('@/views/ProfileView.vue');
const NotificationsView = () => import('@/views/NotificationsView.vue');
const NotFoundView = () => import('@/views/NotFoundView.vue');
const DevicesView = () => import('@/views/devices/DevicesView.vue');
const ErrorView = () => import('@/views/ErrorView.vue');
const AccessErrorView = () => import('@/views/AccessErrorView.vue');
const LayoutsView = () => import('@/views/LayoutsView.vue');
const UsersView = () => import('@/views/UsersView.vue');
const RestorePasswordView = () => import('@/views/password/RestorePasswordView.vue');
const NewPasswordView = () => import('@/views/password/NewPasswordView.vue');
const StatisticsView = () => import('@/views/StatisticsView.vue');
const PlayerView = () => import('@/views/PlayerView.vue');

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: NotFoundView,
    meta: {
      loginRequired: true,
      title: 'Страница не найдена'
    }
  },
  {
    path: '/error',
    name: 'error',
    component: ErrorView,
    meta: {
      loginRequired: true,
      title: 'Ошибка'
    }
  },
  {
    path: '/access-error',
    name: 'accessError',
    component: AccessErrorView,
    meta: {
      loginRequired: true,
      title: 'Нет доступа'
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView,
    meta: {
      loginRequired: true,
      title: 'Профиль'
    }
  },
  {
    path: '/layouts',
    name: 'layouts',
    component: LayoutsView,
    meta: {
      loginRequired: true,
      accessRoles: [userRolesEnum.admin, userRolesEnum.super_admin, userRolesEnum.stp, userRolesEnum.user],
      title: 'Раскладки'
    }
  },
  {
    path: '/users',
    name: 'users',
    component: UsersView,
    meta: {
      loginRequired: true,
      accessRoles: [userRolesEnum.admin, userRolesEnum.super_admin, userRolesEnum.stp, userRolesEnum.vendor],
      title: 'Пользователи'
    }
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: NotificationsView,
    meta: {
      loginRequired: true,
      title: 'Уведомления'
    }
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: StatisticsView,
    meta: {
      loginRequired: true,
      accessRoles: [userRolesEnum.stp],
      title: 'Статистика'
    }
  },
  {
    path: '/player',
    name: 'player',
    component: PlayerView,
    meta: {
      loginRequired: true,
      title: 'Камера'
    }
  },
  {
    path: '/devices',
    name: 'devices',
    component: DevicesView,
    meta: {
      loginRequired: true,
      accessRoles: [userRolesEnum.admin, userRolesEnum.super_admin, userRolesEnum.stp, userRolesEnum.user],
      title: 'Устройства'
    },

    beforeEnter(to, from, next) {
      const devicesStore = useDevicesStore()
      if (!to.query.page) {
        const page = devicesStore.currentView
        next({ name: 'devices', query: { page } })
      } else {
        devicesStore.changeCurrentPage(to.query.page as devicesPagesEnum)
        next()
      }
    }
  },
  {
    path: '/password',
    name: 'password',
    redirect: '/password/restore',
    beforeEnter(to, from, next) {
      const authStore = useAuthStore()

      if (authStore.isAuth) {
        next({ name: 'notFound' })
      } else {
        next()
      }
    },
    children: [
      {
        path: "restore",
        name: 'restore-password',
        component: RestorePasswordView,
        meta: { title: 'Восстановление пароля' },
      },
      {
        path: "new",
        name: 'new-password',
        component: NewPasswordView,
        meta: { title: 'Новый пароль' },
        beforeEnter(to, from, next) {
          if (!to.query.link) {
            next({ name: 'login' })
          } else {
            next()
          }
        }
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: { title: 'Вход' },
    beforeEnter(to, from, next) {
      const authStore = useAuthStore()
      const userStore = useUserStore()

      if (authStore.isAuth) {
        if (userStore.isVendor) {
          next({ name: 'users' })
          return
        }
        next({ name: 'layouts' })
      } else {
        next()
      }
    }
  },
];

function authGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  const loginRequired = !!to.matched.find(({ meta }) => meta.loginRequired)
  const authStore = useAuthStore()

  if (!authStore.isAuth && loginRequired) {
    next({ name: 'login', query: { redirect: to.fullPath } })
  } else {
    next()
  }
}
function accessGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  const userStore = useUserStore()
  const role = userStore.userRole

  if (!role) {
    next()
    return
  }

  const isHaveRights = !!to.matched.find(({ meta }) => {
    if (Array.isArray(meta.accessRoles)) {
      return meta.accessRoles.includes(role)
    }
    return true
  })

  if (isHaveRights) {
    next()
  } else {
    next({ name: 'accessError' })
  }
}

function changeTitle(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  if (to.meta.title) {
    document.title = `PnP — ${to.meta.title}`
  }
  next()
}

function closeMenu() {
  const menuStore = useMenuStore()
  menuStore.closeMenu()
}

function startLoading() {
  const loadingStore = useLoadingStore()
  loadingStore.loadingStart()
}

function stopLoading() {
  const loadingStore = useLoadingStore()
  loadingStore.loadingStop()
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'link-active'
});

router.beforeEach(accessGuard)
router.beforeEach(authGuard)
router.beforeEach(changeTitle)
router.beforeEach(closeMenu)
router.beforeEach(startLoading)
router.afterEach(stopLoading)

export default router;
