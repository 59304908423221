import axios, { AxiosError } from "axios";

const instance = axios.create({
  baseURL: '/api/v1',
  headers: {
    'Content-type': 'application/json',
  }
})

instance.interceptors.request.use((config) => {
  const sessionId = localStorage.getItem('sessionId')
  if (sessionId && config?.headers) {
    config.headers['X-Session-Id'] = sessionId
  }
  return config
})

instance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      localStorage.removeItem('sessionId')
      location.reload()
    }
    throw error
  }
)

export default instance
