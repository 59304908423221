import { defineStore } from 'pinia'
import { rootCamerasFiltersState } from "@/types/store";

export const useCamerasFiltersStore = defineStore('camerasFilters', {
  state: (): rootCamerasFiltersState => ({
    searchValue: '',
    organisations: [],
    connections: [],
    statuses: [],
    echdStates: [],
  })
})
