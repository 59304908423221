<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.92828 14.7848L2.25 12.1065L4.92828 9.42822" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19.1783 14.7848L21.8566 12.1065L19.1783 9.42822" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2.64341 12.1066H21.8566" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.4394 19.296L12.1177 21.9743L14.796 19.296" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.4394 4.92822L12.1177 2.24993L14.796 4.92822" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.1176 2.63242L12.1176 21.8456" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
