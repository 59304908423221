import { defineStore } from 'pinia'
import { rootLayoutsSidebarState } from "@/types/store";
import { layoutsSidebarType } from "@/types/layouts";

export const useSidebarLayoutsStore = defineStore('sidebarLayouts', {
  state: (): rootLayoutsSidebarState => ({
    type: layoutsSidebarType.layoutsList,
    isOpenSidebar: false,
    isCreationDialogActive: false,
    positionToMove: 0
  }),
})
