import axios from "axios";
import {accessToken} from "@/consts/map";

const instance = axios.create({
  baseURL: 'https://api.mapbox.com/geocoding/v5',
})

instance.interceptors.request.use((config) => {
  config.params = {
    ...config.params,
    access_token: accessToken
  }
  return config;
})

export default instance
