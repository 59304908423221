import instance from "@/api/http";
import { actionsOnUsersRequestParams } from "@/types/statistics";
import { devicesActionsResponse, usersActionsResponse } from "@/types/api";

export default {
  getStatusesCount() {
    return instance.get('statistic/echdStates')
  },
  getCameraStatistics() {
    return instance.get('statistic/echdCameras')
  },
  getActionsOnUserStatistic(params: actionsOnUsersRequestParams) {
    return instance.get<usersActionsResponse>('statistic/actionsOnUsers', { params })
  },
  getActionsOnDevicesStatistic(params: actionsOnUsersRequestParams) {
    return instance.get<devicesActionsResponse>('statistic/actionsOnCameras', { params })
  },
  downloadUsersStatistic() {
    return instance.get(`statistic/download/users`, { responseType: 'blob' })
  },
}
