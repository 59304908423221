import { defineStore } from 'pinia'
import api from "@/api/api";
import { notificationRequest, notificationSentType } from "@/types/notifications";
import { rootNotificationsCreationState } from "@/types/store";

export const useNotificationCreationStore = defineStore('notificationCreation', {
  state: (): rootNotificationsCreationState => ({
    notificationDialogActive: false,

    message: '',
    organisationName: '',
    users: [],
    isAll: false,
  }),

  actions: {
    createNotification() {
      let data: notificationRequest

      if (this.isAll) {
        data = {
          message: this.message,
          notificationType: notificationSentType.stp_all,
        }
      } else if (this.users.length) {
        data = {
          message: this.message,
          notificationType: notificationSentType.stp_direct,
          userIds: this.users
        }
      } else {
        data = {
          message: this.message,
          notificationType: notificationSentType.stp_org,
          organisationName: this.organisationName
        }
      }
      return api.notifications.sendNotification(data)
    },
    resetNotificationData() {
      this.message = ''
      this.organisationName = ''
      this.users = []
      this.isAll = false
    }
  }
})
