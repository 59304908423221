<template>
  <div class="error-content" :data-theme="themeStore.getTheme">
    <p class="error-content__text">{{text}}</p>
    <a
      class="error-content__link"
      :href="link"
      target="_blank"
    >
      Посмотреть инструкцию
      <ArrowRightSvg />
    </a>
    <el-button
      size="default"
      type="primary"
      style="width: 100%"
      @click="emit('tryAgain')"
      :data-theme="themeStore.getTheme"
    >
      Попробовать снова
    </el-button>
  </div>
</template>

<script setup>
import ArrowRightSvg from "@/components/app-svg/icons/ArrowRightSvg.vue";
import { defineEmits, defineProps } from "vue";
import { useThemeStore } from "@/store/theme";

const themeStore = useThemeStore()

const props = defineProps({
  link: String,
  text: String,
})
const emit = defineEmits(['update:modelValue', 'confirmData', 'tryAgain'])
</script>

<style scoped lang="scss">
.error-content {
  &__text {
    color: var(--text-color);
    word-break: break-word;
    line-height: 20px;
    margin-bottom: 24px;
  }
  &__link {
    width: 100%;
    color: var(--darkest);
    font-size: 16px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 15px;
    border-radius: 4px;
    border: 1px solid var(--darkest);
    margin-bottom: 14px;
    transition: background 0.2s;

    &:hover {
      background: var(--grey-1);
    }
  }
}

.error-content[data-theme="dark"] {
  .error-content__link {
    border-color: var(--night-grey-7);
    color: var(--night-grey-7);
    fill: var(--night-grey-7);
    &:hover {
      background: var(--night-grey-2);
    }
  }

  .error-content__text {
    color: var(--night-text-color);
  }
}
</style>
