export default function getComplexityRules(val: string, params: { minLength: number } = { minLength: 8 }) {
  const errors = {
    base: false,
    minLength: false,
    uniq: false,
    together: true,
    same: true,
    sameGroup: true,
  }
  // - Пароль должен содержать цифры, буквы, спецсимволы и прописные буквы.
  if (/(?=.*[0-9])(?=.*[!#%&'*,.:;?@`])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!#%&'*,.:;?@`]/g.test(val)) {
    errors.base = true
  }
  // - Минимальная длина пароля - 8 символов.
  if (val.length >= params.minLength) {
    errors.minLength = true
  }
  // Одинаковые рядом символы
  for (let i = 0; i < val.length; i++) {
    if (val[i] === val[i + 1] && val[i] === val[i + 2]) {
      errors.same = false
      break
    }
  }
  // - Уникальные символы
  if (new Set(val.split('')).size >= 5) {
    errors.uniq = true
  }
  // - Рядом на клавиатуре
  const nums = "1234567890-="
  const numsR = "=-0987654321"
  const qwe = "qwertyuiop[]"
  const qweR = "][poiuytrewq"
  const asd = "asdfghjkl;'"
  const asdR = "';lkjhgfdsa"
  const zxc = "zxcvbnm,./"
  const zxcR = "/.,mnbvcxz"

  const size = 3
  const checkCount = val.length - (size - 1)

  for (let i = 0; i < checkCount; i++) {
    const substr = val.substring(i, i + size).toLowerCase()
    if (qwe.includes(substr) || asd.includes(substr) || zxc.includes(substr) || nums.includes(substr)) {
      errors.together = false
      break
    }
    if (qweR.includes(substr) || asdR.includes(substr) || zxcR.includes(substr) || numsR.includes(substr)) {
      errors.together = false
      break
    }
  }
  // Подряд группы
  const valPieces = []
  const step = 3

  for (let i = 0; i < val.length - 2; i++) {
    valPieces.push(val.substring(i, step + i))
  }
  if (new Set(valPieces).size < val.length - 2) {
    errors.sameGroup = false
  }

  return errors
}
