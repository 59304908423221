import Loading from '@/components/app-ui/Loading.vue'
import Spinner from '@/components/app-ui/Spinner.vue'
import PnpInput from '@/components/app-ui/Input.vue'
import Checkbox from '@/components/app-ui/Checkbox.vue'
import Status from '@/components/app-ui/Status.vue'
import Access from "@/components/app-ui/Access.vue";
import Dialog from "@/components/app-ui/Dialog.vue";

export default [
  { name: 'Loading', component: Loading },
  { name: 'Spinner', component: Spinner },
  { name: 'PnpInput', component: PnpInput },
  { name: 'Checkbox', component: Checkbox },
  { name: 'Status', component: Status },
  { name: 'Access', component: Access },
  { name: 'Dialog', component: Dialog },
]
