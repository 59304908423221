import auth from '@/api/modules/auth'
import player from "@/api/modules/player";
import devices from "@/api/modules/devices";
import users from "@/api/modules/users";
import password from "@/api/modules/password";
import layouts from "@/api/modules/layouts";
import comments from "@/api/modules/comments";
import notifications from "@/api/modules/notifications";
import map from "@/api/modules/map";
import cameraSettings from "@/api/modules/cameraSettings";
import dict from "@/api/modules/dict";
import statistics from "@/api/modules/statistics";
import files from "@/api/modules/files";

export default {
  auth,
  player,
  devices,
  users,
  password,
  layouts,
  comments,
  notifications,
  map,
  dict,
  cameraSettings,
  statistics,
  files
}
