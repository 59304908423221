import { defineStore } from 'pinia'
import { rootCameraState } from "@/types/store";
import api from "@/api/api";
import { cameraTypeEnum, creationCameraEnum } from "@/types/devices";
import { useUserStore } from "@/store/user";
import { camera } from "@/types/main";

export const useCameraStore = defineStore('addCamera', {
  state: (): rootCameraState => ({
    connectDialogActive: false,
    connectDialogType: creationCameraEnum.MAC,
    dataDialogActive: false,
    qrDialogActive: false,
    gpsDialogActive: false,

    creationCameraId: '',

    transmitterSerialNumber: '',
    currentTransmitterOrganisation: '',

    ip: '',
    mask: '',
    gateway: '',
    dns: '',
    mac: '',
    rtsp: '',
    wifiSsid: '',
    wifiPassword: '',
    name: '',
    organisationName: '',
    description: '',
    viewDescription: '',
    gps: {
      longitude: 0,
      latitude: 0
    },
    geoAddress: '',
    isInner: false,
    objectTypeId: '',
    serviceTypeId: 0,
    archiveDepth: 432000,
  }),

  actions: {
    updateCamera(id: string) {
      return api.devices.updateCamera({
        sourceId: id,
        cameraName: this.name,
        description: this.description,
        viewDescription: this.viewDescription,
        isInner: this.isInner,
        objectTypeId: this.objectTypeId,
        serviceTypeId: this.serviceTypeId,
        gps: this.gps,
      })
    },
    finishCreatingCamera(id: string) {
      return api.devices.finishCreatingCamera({
        sourceId: id,
        cameraName: this.name,
        description: this.description,
        viewDescription: this.viewDescription,
        isInner: this.isInner,
        objectTypeId: this.objectTypeId,
        serviceTypeId: this.serviceTypeId,
        gps: this.gps,
        geoAddress: this.geoAddress
      })
    },
    // TODO: need?
    updateTransmitterCamera(id: string) {
      return api.devices.updateCamera({
        sourceId: id,
        cameraName: this.name,
        description: this.description,
        viewDescription: this.viewDescription,
        isInner: this.isInner,
        objectTypeId: this.objectTypeId,
        serviceTypeId: this.serviceTypeId,
        gps: this.gps,
      })
    },
    createTestLanCamera(authId: string) {
      const userStore = useUserStore()
      const organisationName = userStore.isStp ? this.organisationName : userStore.userOrganisation

      return api.devices.createCamera({
        cameraName: authId,
        organisationName,
        cameraType: cameraTypeEnum.pnp_lan_cam
      })
    },
    createTestRtspCamera() {
      const userStore = useUserStore()
      const organisationName = userStore.isStp ? this.organisationName : userStore.userOrganisation

      return api.devices.createCamera({
        cameraName: 'rtsp',
        organisationName,
        cameraType: cameraTypeEnum.pnp_rtsp_cam,
        rtsp: this.rtsp,
        archiveDepth: this.archiveDepth,
      })
    },
    createTestMacCamera() {
      const userStore = useUserStore()
      const organisationName = userStore.isStp ? this.organisationName : userStore.userOrganisation

      return api.devices.createCamera({
        cameraName: 'mac',
        organisationName,
        cameraType: cameraTypeEnum.pnp_cam,
        macAddress: this.mac,
        archiveDepth: this.archiveDepth,
      })
    },
    createTestTransmitterCamera() {
      return api.devices.createCamera({
        cameraName: 'transmitter_camera',
        organisationName: this.currentTransmitterOrganisation,
        cameraType: cameraTypeEnum.transmitter_cam,
        rtsp: this.rtsp,
        transmitterSerialNumber: this.transmitterSerialNumber,
        archiveDepth: this.archiveDepth,
      })
    },
    checkCameraStatus(id: string) {
      return api.devices.getCameraById(id)
          .then((res) => {
            const camera = res.data?.data?.items.find((i: camera) => i.id === id)
            if (camera && camera.status === 'activated') {
              return true
            }
            return false
          })
    },
    resetCameraData() {
      this.ip = ''
      this.mask = ''
      this.gateway = ''
      this.dns = ''
      this.mac = ''
      this.rtsp = ''
      this.name = ''
      this.organisationName = ''
      this.description = ''
      this.viewDescription = ''
      this.gps = {
        longitude: 0,
        latitude: 0
      }
      this.isInner = false
      this.objectTypeId = ''
      this.transmitterSerialNumber = ''
      this.serviceTypeId = 0
      this.creationCameraId = ''
    }
  },
  getters: {
  },
})
