import { defineStore } from 'pinia'
import api from "@/api/api";
import { cameraRequestParams, camerasGroupsRequestParams } from "@/types/devices";
import { usersRequestParams } from "@/types/users";
import { rootMainState } from "@/types/store";
import fileLoader from "@/utils/fileLoader";
import { instructionType } from "@/types/main";

export const useMainStore = defineStore('main', {
  state: (): rootMainState => ({
    isOrganisationRequestAllowed: true,
    organisations: [],
    serviceTypes: []
  }),

  actions: {
    getOrganisationsList() {
      if (this.isOrganisationRequestAllowed) {
        this.isOrganisationRequestAllowed = false

        return api.users.getOrganisationsList()
          .then((res) => {
            setTimeout(() => {
              this.isOrganisationRequestAllowed = true
            }, 3000)
            this.organisations = res.data.data?.organisations
            return res.data.data.organisations
          })
      }
      return Promise.resolve(this.organisations)
    },
    getUsersList(payload?: usersRequestParams) {
      return api.users.getUsersList(payload)
    },
    getCameraList(params: cameraRequestParams) {
      return api.devices.getCameraList(params)
    },
    getCameraGroupsList(organisation?: string) {
      const params: camerasGroupsRequestParams = {}
      if (organisation) {
        params.organisationNames = organisation
      }
      return api.devices.getCameraGroupList(params)
    },
    getObjectTypes() {
      return api.dict.getObjectTypes()
    },
    getServiceTypes() {
      if (this.serviceTypes.length) {
        return Promise.resolve(this.serviceTypes)
      }
      return api.dict.getServiceTypes()
        .then((res) => {
          this.serviceTypes = res.data.data?.items
          return res.data.data.items
        })
    },
    getECHDStates() {
      return api.dict.getECHDStates()
    },
    getServiceTypesByOrganisation(name: string) {
      return api.users.getServiceTypesByOrganisation(name)
    },
    getCertificate(organisationId?: string) {
      return api.users.getCertificate(organisationId)
        .then((res) => {
          fileLoader(res.data, 'Certificate', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
        })
    },
    getInstruction(type: instructionType, title: string) {
      return api.files.getInstruction(type)
        .then((res) => {
          fileLoader(res.data, title, 'application/pdf')
        })
    },
    getDesktopApp() {
      return api.files.getDesktopApp()
        .then((res) => {
          fileLoader(res.data, 'pnp_desktop_app', 'application/zip')
        })
    }
  }
})
