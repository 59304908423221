import { useUserStore } from "@/store/user";
import { userRolesEnum } from "@/types/main";
import { DirectiveBinding } from 'vue'

const showAccess = {
  mounted: (el: HTMLElement, binding: DirectiveBinding<userRolesEnum[]>) => {
    const userStore = useUserStore()
    const role = userStore.userRole

    if (role) {
      const isShow = binding.value.includes(role)
      if (!isShow) {
        el.style.display = 'none'
        el.remove()
      }
    }
  }
}
export default showAccess
