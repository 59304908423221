import { defineStore } from 'pinia'
import api from "@/api/api";
import { rootOrganisationCreationState } from "@/types/store";
import { organisationTypeEnum, userRolesEnum } from "@/types/main";
import unformatPhone from "@/utils/unformatPhone";
import { useUserStore } from "@/store/user";
import { organisationCreationData, userCreationData } from "@/types/users";

export const useOrganisationCreationStore = defineStore('organisationCreation', {
  state: (): rootOrganisationCreationState => ({
    organisationDialogActive: false,
    userDialogActive: false,

    firstName: '',
    lastName: '',
    patronymic: '',
    userLogin: '',
    userPassword: '',
    userEmail: '',
    userPhone: '',

    organisationName: '',
    organisationAddress: '',
    organisationEmail: '',
    organisationPhone: '',
    organisationServiceTypes: [],
  }),

  actions: {
    createOrganisation() {
      const userStore = useUserStore()

      const payload: organisationCreationData = {
        name: this.organisationName,
        address: this.organisationAddress,
        email: this.organisationEmail,
        phone: unformatPhone(this.organisationPhone),
        type: organisationTypeEnum.entity
      }
      if (userStore.isStp) {
        payload.serviceTypeIds = this.organisationServiceTypes
      }

      return api.users.createOrganisation(payload)
    },
    createUser() {
      const payload: userCreationData = {
        role: userRolesEnum.super_admin,
        firstName: this.firstName,
        lastName: this.lastName,
        patronymic: this.patronymic,
        organisationName: this.organisationName,
        login: this.userLogin,
        email: this.userEmail,
        phone: unformatPhone(this.userPhone)
      }
      if (this.userPassword) {
        payload.password = this.userPassword
      }
      return api.users.createUser(payload)
    },
    deleteOrganisation(id: string) {
      return api.users.deleteOrganisation(id)
    },
    resetOrganisationData() {
      this.organisationName = ''
      this.organisationAddress = ''
      this.organisationEmail = ''
      this.organisationPhone = ''
    },
    resetUserData() {
      this.firstName = ''
      this.lastName = ''
      this.patronymic = ''
      this.userLogin = ''
      this.userEmail = ''
      this.userPhone = ''
      this.organisationServiceTypes = []
    }
  },
})
