import instance from "@/api/http";
import {
  cameraCreationData,
  cameraRequestParams,
  camerasGroupCreationData,
  camerasGroupsRequestParams,
  camerasGroupUpdateData,
  cameraUpdateData, positionPTZ,
  transmitterCreationData,
  transmittersRequestParams,
  transmitterUpdateData
} from "@/types/devices";

export default {
  createTransmitter(data: transmitterCreationData) {
    return instance.post('transmitters', data)
  },
  createCamera(data: cameraCreationData) {
    return instance.post('camera', data)
  },
  finishCreatingCamera(data: cameraUpdateData) {
    return instance.patch('camera/create', data)
  },
  createCameraGroup(data: camerasGroupCreationData) {
    return instance.post('cameraGroup', data)
  },
  getDevices() {
    return instance.get('devices')
  },
  getTransmitterList(params: transmittersRequestParams) {
    return instance.get('transmitters', { params })
  },
  getCameraList(params: cameraRequestParams) {
    return instance.get('camera', { params })
  },
  getCameraById(id: string) {
    const params = { sourceIds: id }
    return instance.get('camera', { params })
  },
  getCameraGroupList(params: camerasGroupsRequestParams) {
    return instance.get('cameraGroup', { params })
  },
  deleteTransmitter(serialNumber: string) {
    return instance.delete(`transmitters/${serialNumber}`)
  },
  deleteCamera(id: string, notificationId?: string) {
    const params = { notificationId }
    return instance.delete(`camera/${id}`, { params })
  },
  deactivateCamera(id: string) {
    return instance.patch(`camera/deactivate/${id}`)
  },
  rejectCameraDeleting(id: string) {
    return instance.patch(`camera/reject/${id}`)
  },
  deleteCameraGroup(id: string) {
    return instance.delete(`cameraGroup/${id}`)
  },
  updateTransmitter(data: transmitterUpdateData) {
    return instance.patch('transmitters', data)
  },
  updateCamera(data: cameraUpdateData) {
    return instance.patch('camera', data)
  },
  updateCameraGroup(data: camerasGroupUpdateData) {
    return instance.patch('cameraGroup', data)
  },
  registerCameraInHP(sourceId: string) {
    const data = { sourceId }
    return instance.patch('camera/registerHP', data)
  },
  getCameraConnection(cameraId: string) {
    return instance.get(`ptz/connect/${cameraId}`)
  },
  getCameraPositions(cameraId: string) {
    return instance.get(`ptz/position/${cameraId}`)
  },
  deleteCameraPosition(id: string) {
    return instance.delete(`ptz/position/${id}`)
  },
  updateCameraPosition(position: Omit<positionPTZ, 'createdAt'>) {
    return instance.patch(`ptz/position`, position)
  },
  createCameraPosition(position: Omit<positionPTZ, 'id' | 'createdAt'>) {
    return instance.post(`ptz/position`, position)
  },
  setHomePosition(id: string) {
    return instance.patch(`ptz/position/home/${id}`)
  },
  getObjectTypes() {
    return instance.get('camera/objectTypes')
  },
  getServiceTypes() {
    return instance.get('camera/serviceTypes')
  }
}
