import instance from "@/api/http";
import {
  usersRequestParams,
  organisationCreationData,
  userCreationData,
  userUpdateData,
  organisationUpdateData, organisationsRequestParams
} from "@/types/users";
import {
  archiveAccessResponse,
  mixinBinResponse,
  organisationResponse,
  serviceTypesIdsResponse,
  usersResponse
} from "@/types/api";
import { playerAction } from "@/types/player";

export default {
  getUsersList(params?: usersRequestParams) {
    return instance.get<usersResponse>('user', { params })
  },
  getOrganisationsList(params?: organisationsRequestParams) {
    return instance.get<organisationResponse>('organisations', { params })
  },
  createUser(data: userCreationData) {
    return instance.post('user', data)
  },
  updateUser(data: userUpdateData) {
    return instance.patch('user', data)
  },
  deleteUser(id: string) {
    return instance.delete(`user/${id}`)
  },
  hideUser(id: string) {
    return instance.patch(`user/${id}`)
  },
  createOrganisation(data: organisationCreationData) {
    return instance.post('organisations', data)
  },
  updateOrganisation(data: organisationUpdateData) {
    return instance.patch('organisations', data)
  },
  deleteOrganisation(id: string) {
    return instance.delete(`organisations/${id}`)
  },
  setNotificationsStatus(status: boolean) {
    return instance.patch(`user/notification`, { status })
  },
  getUserById(id: string) {
    const params = { userIds: id }
    return instance.get(`user`, { params })
  },
  getOrganisationById(id: string) {
    const params = { ids: id }
    return instance.get<organisationResponse>('organisations', { params })
  },
  getMixinBin() {
    return instance.get<mixinBinResponse>(`user/mixinBin`)
  },
  getServiceTypesByOrganisation(name: string) {
    return instance.get<serviceTypesIdsResponse>(`organisations/serviceTypeIds/${name}`)
  },
  getCertificate(id?: string) {
    const params = { id }
    return instance.get(`user/certificate`, { params, responseType: 'blob' })
  },
  getAgreement() {
    const params = { type: 'pdf' }
    return instance.get(`user/agreement/download`, { params, responseType: 'blob' })
  },
  getArchiveAccess() {
    return instance.get<archiveAccessResponse>(`user/archiveAccess`)
  },
  sendPlayerActions(cameraId: string, actions: Array<playerAction>) {
    return instance.post(`user/sendPlayerAction`, { cameraId, actions })
  }
}
