import { defineStore } from 'pinia'
import api from "@/api/api";
import { playerParams } from "@/types/devices";
import { playerAction } from "@/types/player";

export const usePlayerStore = defineStore('player', {
  state: (): {mixinId: number} => ({
    mixinId: 0
  }),

  actions: {
    initPlayer(id: string, params: playerParams) {
      return api.player.initPlayer(id, params)
    },
    getMixinBin() {
      return api.users.getMixinBin()
        .then((res) => {
          this.mixinId = res.data.data.mixinBin
        })
    },
    sendActions(cameraId: string, actions: Array<playerAction>) {
      return api.users.sendPlayerActions(cameraId, actions)
    }
  }
})
