import instance from "@/api/http";
import { instructionType } from "@/types/main";

export default {
  getInstruction(type: instructionType) {
    const params = {
      kind: type
    }
    return instance.get('files/instruction', { params, responseType: 'blob' })
  },
  getVideo() {
    return instance.get('files/instruction/video', { responseType: 'blob' })
  },
  getDesktopApp() {
    return instance.get('files/app/windows', { responseType: 'blob' })
  },
}
