import { defineStore } from 'pinia'
import { rootThemeState } from "@/types/store";
import { themeEnum } from "@/types/theme";

export const useThemeStore = defineStore('theme', {
  state: (): rootThemeState => ({
    theme: localStorage.getItem('theme') as themeEnum || 'light'
  }),

  actions: {
    setTheme(theme: themeEnum) {
      this.theme = theme
      localStorage.setItem('theme', theme)
    }
  },

  getters: {
    getTheme: (state) => state.theme,
    isDark: (state) => state.theme === themeEnum.dark,
    isLight: (state) => state.theme !== themeEnum.dark,
  },
})
