import { defineStore } from 'pinia'
import { rootOrganisationsState } from "@/types/store";
import { organisationsRequestParams } from "@/types/users";
import api from "@/api/api";
import { useLoadingStore } from "@/store/loading";

export const useOrganisationsStore = defineStore('organisations', {
  state: (): rootOrganisationsState => ({
    organisationSearchValue: '',
    pageSize: 40,
    currentPage: 1,
    total: 0,
    organisations: [],
    isError: false
  }),

  actions: {
    getOrganisationsList() {
      const loadingStore = useLoadingStore()
      loadingStore.loadingStart()

      const params: organisationsRequestParams = {
        limit: this.pageSize,
        offset: (this.currentPage - 1) * this.pageSize,
      }

      if (this.organisationSearchValue) {
        params.search = this.organisationSearchValue
      }
      return api.users.getOrganisationsList(params)
        .then((res) => {
          if (res.data?.data?.organisations) {
            this.organisations = res.data?.data?.organisations
            this.total = res.data.data.count
          }
        })
        .catch(() => {
          this.isError = true
        })
        .finally(() => {
          loadingStore.loadingStop()
        })
    }
  }
})
