import { defineStore } from "pinia";
import { useAuthStore } from "@/store/auth";
import { rootControlsState } from "@/types/store";
import api from "@/api/api";
import { positionPTZ } from "@/types/devices";

export const useControlsStore = defineStore('controls', {
  state: (): rootControlsState => ({
    socket: null,
    cameraId: '',
    positionActionType: 'update',
    editPositionId: '',
    positionName: '',
    azimuth: '',
    slope: '',
    zoom: '',
    isEditActive: false,
    positions: []
  }),

  actions: {
    initSocket(url: string) {
      try {
        const authStore = useAuthStore()
        this.socket = new WebSocket(`${url}/${authStore.sessionId}`)
        this.socket.onopen = () => {
          console.log('Successfully connected to the echo websocket server.')
        }
        // this.socket.onmessage = (event) => {
        //   const data = JSON.parse(event.data)
        //   // this.notificationProcessing(data.data as notification)
        // }
        this.socket.onclose = () => {
          console.log('Socket closed')
          // if (authStore.isAuth) {
            // setTimeout(() => {
            //   this.initSocket(url)
            // }, 2000)
          // }
        }
      } catch (e) {
        console.log(e)
      }
    },
    closeSocket() {
      if (this.socket) {
        this.socket.close()
        this.socket = null
      }
    },
    moveStop() {
      this.socket?.send(JSON.stringify({
        action: 'Stop'
      }))
    },
    moveStart(action: string) {
      this.socket?.send(JSON.stringify({
        action
      }))
    },
    sendAbsolutePosition(azimuth: number, slope: number, zoom: number) {
      console.log('sendAbsolutePosition', azimuth, slope, zoom)
      this.socket?.send(JSON.stringify({
        action: "Absolutely",
        arg1: azimuth,
        arg2: slope,
        arg3: zoom
      }))
    },
    getPositions() {
      return api.devices.getCameraPositions(this.cameraId)
        .then((res) => {
          this.positions = res.data.data
        })
    },
    deletePosition(id: string) {
      return api.devices.deleteCameraPosition(id)
    },
    updatePosition(position: Omit<positionPTZ, 'createdAt'>) {
      return api.devices.updateCameraPosition(position)
    },
    createPosition(position: Omit<positionPTZ, 'id' | 'createdAt'>) {
      return api.devices.createCameraPosition(position)
    },
    setHomePosition(id: string) {
      return api.devices.setHomePosition(id)
    }
  }
})
