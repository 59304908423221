import instance from "@/api/http";
import { notificationRequest } from "@/types/notifications";
import { notificationsRequestParams } from "@/types/users";

export default {
  getNotifications(params: notificationsRequestParams) {
    return instance.get('notifications', { params })
  },
  sendNotification(data: notificationRequest) {
    return instance.post('notifications', data)
  },
  seeNotification(id: string) {
    return instance.patch(`notifications/${id}`)
  }
}
