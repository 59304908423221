import instance from "@/api/http";
import { settingsRequestParams, allSettingsRequestParams } from "@/types/cameraSettings";

export default {
  sendCommand(params: settingsRequestParams) {
    return instance.patch('camera/sendCommand', params)
  },
  sendCommandToAllCameras(params: allSettingsRequestParams) {
    return instance.patch('camera/sendCommandAll', params)
  },
  getOptions(id: string) {
    return instance.get(`camera/options/${id}`)
  }
}
