import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import ElementPlus from 'element-plus'
import ru from 'element-plus/es/locale/lang/ru'
import 'element-plus/dist/index.css'
import '@/styles/main.scss'
import { createPinia } from 'pinia';
import vClickOutside from 'click-outside-vue3'
import Maska from "maska";
import showAccess from "@/directives/showAccess";
import components from '@/plugins/components'
import { initYandexMetrika } from 'yandex-metrika-vue3';

const app = createApp(App)

components.forEach((c) => {
  app.component(c.name, c.component);
})

app
  .use(createPinia())
  .use(vClickOutside)
  .use(Maska)
  .use(router)
  .use(initYandexMetrika, {
    id: 92965105,
    router,
    env: process.env.NODE_ENV
  })
  .use(ElementPlus, { locale: ru })
  .directive('showAccess', showAccess)
  .mount('#app');
