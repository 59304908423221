import { defineStore } from 'pinia'
import { rootCameraGroupState } from "@/types/store";
import api from "@/api/api";
import { useUserStore } from "@/store/user";
import { camerasGroupCreationData } from "@/types/devices";

export const useCameraGroupStore = defineStore('addCameraGroup', {
  state: (): rootCameraGroupState => ({
    dataDialogActive: false,

    groupName: '',
    description: '',
    cameraIds: [],
    organisationName: '',
  }),

  actions: {
    createCameraGroup() {
      const payload: camerasGroupCreationData = {
        groupName: this.groupName,
        description: this.description,
        cameraIds: this.cameraIds,
      }
      const userStore = useUserStore()
      if (userStore.isStp) {
        payload.organisationName = this.organisationName
      }
      return api.devices.createCameraGroup(payload)
    },
    resetCameraGroupData() {
      this.groupName = ''
      this.description = ''
      this.cameraIds = []
      this.organisationName = ''
    }
  },
})
