import { defineStore } from 'pinia'
import { rootAppNotificationState } from "@/types/store";
import { appNotifications } from "@/types/appNotifications";

export const useAppNotificationsStore = defineStore('appNotifications', {
  state: (): rootAppNotificationState => ({
    notifications: []
  }),

  actions: {
    notify(notification: Omit<appNotifications, 'id'>, duration = 5) {
      const id = Date.now()
      this.notifications.push({ ...notification, id })
      if (duration) {
        setTimeout(() => {
          this.notifications = this.notifications.filter((i) => i.id !== id)
        }, duration * 1000)
      }
    },
    closeNotification(id: number) {
      this.notifications = this.notifications.filter((i) => i.id !== id)
    }
  }
})
