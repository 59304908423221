import instance from "@/api/http";
import { serviceTypesResponse } from "@/types/api";

export default {
  getObjectTypes() {
    return instance.get('dict/objectTypes')
  },
  getServiceTypes() {
    return instance.get<serviceTypesResponse>('dict/serviceTypes')
  },
  getECHDStates() {
    return instance.get('dict/echdStates')
  }
}
