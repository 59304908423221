import { defineStore } from 'pinia'
import api from "@/api/api";
import { rootNotificationsState } from "@/types/store";
import { userNotification } from "@/types/notifications";
import { notificationsRequestParams } from "@/types/users";
import { useLoadingStore } from "@/store/loading";

export const useNotificationsStore = defineStore('notifications', {
  state: (): rootNotificationsState => ({
    searchValue: '',
    notifications: [],
    currentPage: 1,
    total: 0,
    pageSize: 40,
  }),

  actions: {
    getNotifications() {
      const loadingStore = useLoadingStore()
      loadingStore.loadingStart()

      const params: notificationsRequestParams = {
        limit: this.pageSize,
        offset: (this.currentPage - 1) * this.pageSize,
      }
      if (this.searchValue) {
        params.search = this.searchValue
      }
      return api.notifications.getNotifications(params)
        .then((res) => {
          this.notifications = res.data?.data?.items
          this.total = res.data?.data?.count
        })
        .finally(() => {
          loadingStore.loadingStop()
        })
    },
    seeNotification(id: string) {
      return api.notifications.seeNotification(id)
    },
    addNotification(notification: userNotification) {
      this.notifications.unshift(notification)
    }
  }
})
