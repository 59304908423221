import { defineStore } from "pinia";

export const useLoadingStore = defineStore('loading', {
  state: (): {active: boolean} => ({
    active: false
  }),

  actions: {
    loadingStart() {
      this.active = true
    },
    loadingStop() {
      this.active = false
    }
  }
})
