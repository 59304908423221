<template>
  <transition name="loading-bar">
    <div v-if="loadingStore.active" class="loading-bar" :class="{dark: themeStore.isDark}"></div>
  </transition>
</template>

<script setup>
import { useLoadingStore } from "@/store/loading";
import { useThemeStore } from "@/store/theme";

const loadingStore = useLoadingStore()
const themeStore = useThemeStore()
</script>

<style lang="scss" scoped>
.loading-bar {
  display: block;
  z-index: calc(var(--index-5) + 1);
  margin: auto;
  width: 100%;
  height: 4px;
  position: fixed;
  top: 0;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background-image: linear-gradient(90deg, var(--grey-2) 10%, var(--red-2) 20%, var(--grey-3) 40%, var(--grey-4) 60%,
      var(--red-2) 80%, var(--grey-2) 100%);
    background-position: 0 0;
    background-repeat: repeat-x;
    animation: movebar 1.5s linear infinite;
  }
  &.dark {
    &::before {
      background-image: linear-gradient(90deg, var(--night-grey-1) 10%, var(--night-red-5) 30%, var(--night-grey-3) 40%, var(--night-grey-2) 60%,
        var(--night-red-5) 80%, var(--night-grey-1) 100%);
    }
  }
}

@keyframes movebar {
  from {background-position: 0 0}
  to {background-position: 100vw 0}
}

.loading-bar-enter-from,
.loading-bar-leave-to {
  transform: translateY(-5px);
}

.loading-bar-enter-active,
.loading-bar-leave-active {
  transition: transform .2s ease-out;
}
</style>

<style scoped lang="scss">
@media (max-width: 768px) {
  .loading-bar {
    height: 2px;

    &::before {
      background-image: linear-gradient(90deg, var(--grey-1) 10%, var(--red-3) 40%, var(--grey-4) 60%, var(--grey-1) 100%);
    }
    &.dark {
      &::before {
        background-image: linear-gradient(90deg, var(--night-grey-1) 10%, var(--night-red-3) 40%, var(--night-grey-3) 60%, var(--night-grey-1) 100%);
      }
    }
  }
}
</style>
