import { defineStore } from 'pinia'
import { rootDialogsState } from "@/types/store";
import { successDialogType } from "@/types/main";

export const useDialogStore = defineStore('dialogs', {
  state: (): rootDialogsState => ({
    successDialogActive: false,
    successDialogText: '',
    successDialogType: successDialogType.default,

    errorDialogActive: false,
    errorDialogText: '',
  }),

  actions: {
    activateSuccess(text: string, type: successDialogType = successDialogType.default) {
      this.successDialogText = text
      this.successDialogType = type
      this.successDialogActive = true
    },
    activateError(text: string) {
      this.errorDialogText = text
      this.errorDialogActive = true
    }
  },
  getters: {
  },
})
