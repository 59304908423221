<template>
  <Dialog
    v-model="active"
    :width="330"
    class="dialog"
  >
    <h2 class="dialog__title">{{title}}</h2>
    <slot />
    <el-button
      type="danger"
      style="width: 100%"
      @click="emit('confirm')"
      :loading="loading"
      :data-theme="themeStore.getTheme"
    >
      <template #loading>
        <Spinner color="white" small style="margin-right: 8px"/>
      </template>
      {{btnText}}
    </el-button>
  </Dialog>
</template>

<script setup>
import { computed, defineEmits, defineProps } from "vue";
import { useThemeStore } from "@/store/theme";

const themeStore = useThemeStore()

const props = defineProps({
  modelValue: Boolean,
  loading: Boolean,
  btnText: String,
  title: String,
})

const emit = defineEmits([
  'update:modelValue', 'confirm'
])

const active = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val)
})
</script>

<style scoped lang="scss">
.dialog {
  &__title {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 15px;
    word-break: break-word;
  }
}
</style>
