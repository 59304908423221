<template>
  <Dialog
    v-model="active"
    :width="500"
    class="dialog"
  >
    <h2 class="dialog__title" :data-theme="themeStore.getTheme">Введите данные нового устройства</h2>
    <PnpInput
      :model-value="name"
      @input="emit('update:name', $event)"
      placeholder="Название"
      class="dialog__input"
      clearable
    />
    <PnpInput
      :model-value="description"
      @input="emit('update:description', $event)"
      placeholder="Описание"
      class="dialog__input"
      type="textarea"
      clearable
    />
    <el-button
      type="primary"
      style="width: 100%"
      @click="emit('confirm')"
      :disabled="confirmDisabled"
      :data-theme="themeStore.getTheme"
    >
      Далее
    </el-button>
  </Dialog>
</template>

<script setup>
import { computed, defineProps, defineEmits, } from "vue";
import { useThemeStore } from "@/store/theme";

const themeStore = useThemeStore()

const props = defineProps({
  modelValue: Boolean,
  name: String,
  description: String,
})
const emit = defineEmits([
  'update:modelValue', 'update:name', 'update:description', 'confirm', 'close'
])
const active = computed({
  get: () => props.modelValue,
  set: (val) => emit("update:modelValue", val)
})

const confirmDisabled = computed(() => !props.name || (props.showOrganisation && !props.organisationName))

</script>

<style scoped lang="scss">
.dialog {
  &__title {
    color: var(--text-color);
    font-size: 20px;
    text-align: center;
    margin-bottom: 30px;
  }
  &__input {
    width: 100%;
    margin-bottom: 16px;
  }
}
.dialog {
  &__title[data-theme='dark'] {
    color: var(--white);
  }
}
</style>
