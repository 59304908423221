import { defineStore } from 'pinia'
import { rootDevicesState } from "@/types/store";
import { devicesPagesEnum, devicesTabsEnum } from "@/types/devices";

export const useDevicesStore = defineStore('devices', {
  state: (): rootDevicesState => ({
    currentView: devicesPagesEnum.list,
    devicesTab: devicesTabsEnum.devices,
  }),

  actions: {
    changeCurrentPage(val: devicesPagesEnum) {
      this.currentView = val
    }
  },
})
