import { defineStore } from 'pinia'
import { rootGroupsState } from "@/types/store";
import { camerasGroupsRequestParams } from "@/types/devices";
import api from "@/api/api";
import { cameraGroup } from "@/types/main";
import { useCamerasGroupsFiltersStore } from "@/store/devices/camerasGroupsFilters";
import { useLoadingStore } from "@/store/loading";

export const useGroupsStore = defineStore('groups', {
  state: (): rootGroupsState => ({
    cameraGroups: [],
    camerasWithoutGroups: [],
    isError: true,
    currentPage: 1,
    total: 0,
    pageSize: 40,
  }),

  actions: {
    getCameraGroupsList() {
      const loadingStore = useLoadingStore()
      loadingStore.loadingStart()

      const camerasGroupsFiltersStore = useCamerasGroupsFiltersStore()
      const params: camerasGroupsRequestParams = {}

      if (camerasGroupsFiltersStore.organisations.length) {
        params.organisationNames = camerasGroupsFiltersStore.organisations.join()
      }

      if (camerasGroupsFiltersStore.searchValue) {
        params.search = camerasGroupsFiltersStore.searchValue
      }

      return api.devices.getCameraGroupList(params)
        .then((res) => {
          if (res.data?.data?.groups) {
            this.camerasWithoutGroups = res.data.data.groups.filter((i: cameraGroup) => i.groupName === 'WithoutGroup')
            this.cameraGroups = res.data.data.groups.filter((i: cameraGroup) => i.groupName !== 'WithoutGroup')
          }
        })
        .catch(() => {
          this.isError = true
        })
        .finally(() => {
          loadingStore.loadingStop()
        })
    }
  },
})
