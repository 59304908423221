import { defineStore } from 'pinia'
import api from '@/api/api'
import { rootLayoutsState } from "@/types/store";
import { grid } from "@/types/layouts";
import { useUserStore } from "@/store/user";
import { useLoadingStore } from "@/store/loading";

export const useLayoutsStore = defineStore('layouts', {
  state: (): rootLayoutsState => ({
    grid: grid.grid4,
    currentPage: 1,
    total: 0,
    layouts: [],
    cameras: []
  }),

  actions: {
    createLayout(name: string, description: string) {
      return api.layouts.createLayout(name, description)
        .then(() => {
          this.getLayoutsList()
        })
    },
    deleteLayout(id: string) {
      return api.layouts.deleteLayout(id)
    },
    getLayoutsList() {
      return api.layouts.getLayoutsList()
        .then((res) => {
          this.layouts = res.data.data?.items
        })
    },
    addCamera(cameraId: string, position?: number) {
      const userStore = useUserStore()
      return api.layouts.addCamera(userStore.userId, cameraId, position)
    },
    deleteCamera(id: string) {
      return api.layouts.deleteCamera(id)
    },
    getLayoutCameras() {
      const userStore = useUserStore()
      const loadingStore = useLoadingStore()
      loadingStore.loadingStart()

      const from = ((this.currentPage - 1) * this.grid) + 1
      const to = this.grid * this.currentPage
      return api.layouts.getLayoutCameras(userStore.userId, from, to)
        .then((res) => {
          if (res.data.data?.cameras) {
            const cameras = []
            const arrayLength = this.grid
            for (let i = 1; i <= arrayLength; i++) {
              const camera = res.data.data?.cameras.find((item) => item.position === i + from - 1)
              if (camera) {
                cameras.push(camera)
              } else {
                cameras.push({ position: i })
              }
            }
            this.cameras = cameras
          }
          if (res.data.data?.maxPosition) {
            this.total = Number(res.data.data?.maxPosition) + this.grid
          } else {
            this.total = this.cameras.length
          }
        })
        .finally(() => {
          loadingStore.loadingStop()
        })
    },
    updatePositionCamera(cameraId: string, position: number) {
      const userStore = useUserStore()
      return api.layouts.updatePositionCamera(userStore.userId, cameraId, position)
    },
    copyToDefaultLayout(id: string) {
      return api.layouts.copyToDefaultLayout(id)
    },
    clearLayout() {
      return api.layouts.clearLayout()
    }
  },
})
