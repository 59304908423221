import { camera } from "@/types/main";

export enum grid {
  'grid1'= 1,
  'grid4'= 4,
  'grid9'= 9,
}

export enum layoutsSidebarType {
  additionPlayer,
  layoutsList
}

export enum actionType {
  update = 'update',
  create = 'create'
}

export type layoutCamera = {
  camera?: camera,
  position: number
}

export type layoutType = {
  layoutId: string
  layoutName: string
  description: string
  createdAt: string
  cameras: Array<string>
}
