import { defineStore } from 'pinia'
import { rootArchiveAccessState } from "@/types/store";
import api from "@/api/api";

export const useArchiveAccessStore = defineStore('archiveAccess', {
  state: (): rootArchiveAccessState => ({
    access: false,
    isCache: false,
  }),

  actions: {
    throttleGetArchiveAccess() {
      if (this.isCache) {
        return Promise.resolve(this.access)
      }
      this.isCache = true
      return this.getArchiveAccess()
        .then(() => {
          setTimeout(() => {
            this.isCache = false
          }, 5000)
        })
    },
    getArchiveAccess() {
      return api.users.getArchiveAccess()
        .then((res) => {
          this.access = res.data.data.access
        })
    },
    cacheAccess() {
      this.isCache = false
    }
  }
})
