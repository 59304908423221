import { defineStore } from 'pinia'
import { rootUsersState } from "@/types/store";
import { usersRequestParams, usersTabEnum } from "@/types/users";
import api from "@/api/api";
import { useUsersFiltersStore } from "@/store/users/usersFilters";
import { useLoadingStore } from "@/store/loading";

export const useUsersStore = defineStore('users', {
  state: (): rootUsersState => ({
    usersSearchValue: '',
    pageSize: 40,
    currentPage: 1,
    total: 0,
    usersTab: usersTabEnum.users,
    users: [],
    isError: false
  }),

  actions: {
    getUsersList() {
      const loadingStore = useLoadingStore()
      loadingStore.loadingStart()

      const usersFiltersStore = useUsersFiltersStore()
      const params: usersRequestParams = {
        limit: this.pageSize,
        offset: (this.currentPage - 1) * this.pageSize,
      }

      if (usersFiltersStore.organisations.length) {
        params.organisationNames = usersFiltersStore.organisations.join()
      }
      if (usersFiltersStore.roles.length) {
        params.roles = usersFiltersStore.roles.join()
      }
      if (this.usersSearchValue) {
        params.search = this.usersSearchValue
      }
      return api.users.getUsersList(params)
        .then((res) => {
          if (res.data?.data?.items) {
            this.users = res.data?.data?.items
            this.total = res.data?.data?.count
          }
        })
        .catch(() => {
          this.isError = true
        })
        .finally(() => {
          loadingStore.loadingStop()
        })
    }
  }
})
