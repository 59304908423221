export default (msg: string) => {
  switch (msg) {
    case 'Layout with such name already exists':
      return 'Раскладка с таким названием уже существует'
    case 'Camera already exists in this layout':
      return 'Такая камера уже есть в раскладке'
    case 'Forbidden':
      return 'Недостаточно прав, обратитесь в службу технической поддержки'
    case 'Super-admin in this organisation already exists':
      return 'Представитель клиента в этой организации уже существует'
    case 'Transmitter already exists':
      return 'Ретранслятор уже существует'
    case 'Trying to create organisation with existing parameters':
      return 'Организция с такими параметрами уже существует'
    case 'Trying to create user with existing parameters':
      return 'Пользователь с такими параметрами уже существует'
    case 'Mac address is already exists':
      return 'Камера с таким MAC-адресом уже существует'
    case 'Camera does not response':
      return 'Камера не отвечает'
    case 'Camera already deactivated':
      return 'Камера уже отключена'
    case 'Invalid mac address':
      return 'Неверный MAC-адрес'
    case 'Trying to add camera with existing parameters':
      return 'Камера с такими параметрами уже существует'
    case 'Duplicate row in DB':
      return 'Повторяющаяся строка в БД'
    case 'Validation error':
      return 'Ошибка валидации'
    case 'Internal Server Error':
      return 'Внутренняя ошибка сервера'
    case 'Bad Request':
      return 'Некорректный запрос'
    case 'No object type with such ID':
      return 'Нет типа объекта с таким идентификатором'
    case 'No service type with such ID':
      return 'Нет типа услуги с таким идентификатором'
    case 'Password is too easy':
      return 'Пароль слишком простой'
    case 'Login or email already exists':
      return 'Логин или электронная почта уже существует'
    case 'New password same as old':
      return 'Новый пароль такой же, как и старый'
    case 'Layout is empty':
      return 'В раскладке нет камер'
    case 'Binding error':
      return 'Ошибка привязки'
    default:
      return msg
  }
}

// Bad Request
// Forbidden
// Internal Server Error
// Not Found
// Binding error
// Validation error
// Trying to add camera with existing parameters
// No transmitter found for serial number
// Max amount of channels reached
// Transmitter %s not confirmed
// Transmitter belongs to other organisation
// Organisation does not exists
// Trying to add camera that is already exists
// Wrong camera type
//   No transmitter data provided
// No camera with such ID
// Camera with such parameters already exists
// Camera does not exist
// Camera already activated
// Camera already deactivated
// Error opening file
// Layout with such name already exists
// Trying to delete default layout
// No layout with such ID belongs to this user
// Layout does not exists
// Camera already exists in this layout
// No camera with such ID in this layout
// No layout found with such ID
// Layout is empty. Nothing to copy
// Organisation with such name already exists
// No organisation with such ID
// Transmitter already exists
// Wrong login
// Wrong password
// Please, confirm user agreement
// Wrong link ID
// User ID not exists
// User already confirmed
// Email not found
// User related with this link no longer exists
// New password same as old
// Bad password
// Super-admin in this organisation already exists
// Login or email already exists
// Forbidden role filter
// User with such parameters already exists
// No user with such ID
