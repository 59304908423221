import { defineStore } from 'pinia'
import { rootTransmitterState } from "@/types/store";
import api from "@/api/api";
import { useUserStore } from "@/store/user";

export const useTransmitterStore = defineStore('addTransmitter', {
  state: (): rootTransmitterState => ({
    connectDialogActive: false,
    dataDialogActive: false,
    gpsDialogActive: false,
    errorDialogActive: false,
    name: '',
    serialNumber: '',
    organisationName: '',
    description: '',
    gps: {
      longitude: 0,
      latitude: 0
    },
    geoAddress: ''
  }),

  actions: {
    createTransmitter() {
      const userStore = useUserStore()
      const organisationName = userStore.isStp ? this.organisationName : userStore.userOrganisation

      return api.devices.createTransmitter({
        name: this.name,
        serialNumber: this.serialNumber,
        organisationName
      })
    },
    updateTransmitter() {
      return api.devices.updateTransmitter({
        name: this.name,
        serialNumber: this.serialNumber,
        geoAddress: this.geoAddress,
        description: this.description,
        gps: this.gps,
      })
    },
    resetTransmitterData() {
      this.name = ''
      this.serialNumber = ''
      this.organisationName = ''
      this.description = ''
      this.geoAddress = ''
      this.gps.latitude = 0
      this.gps.longitude = 0
    }
  }
})
