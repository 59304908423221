import { cameraTypeEnum } from "@/types/devices";
import { nullable } from "@/types/helpers";

export type gps = {
  longitude: number
  latitude: number
}

export enum rightsEnum {
  archive_access= 'archive_access',
}

export enum sidebarTab {
  info,
  editing,
  detailsDeleting,
  deleting,
  deleteSuccess,
  cameras,
  comments,
  settings,
}
export type createdBy = Omit<user, 'phone' | 'parentUserId' | 'createdBy'> & {createdBy: string}

export type objectType = {
  id: string
  name: string
  description: string
}

export type serviceType = {
  id: number
  title: string
  abbreviation: string
}

export type camera = {
  id: string
  cameraName: string
  organisationName: string
  cameraType: cameraTypeEnum
  isCreatingCompleted: boolean
  gps: nullable<gps>
  geoAddress: string
  createdAt: string
  createdBy: createdBy
  createdByName: string
  createdByLastName: string
  createdByPatronymic: string
  serviceType: nullable<serviceType>
  model: string
  isInner?: boolean
  macAddress: string
  isActivated: boolean
  isOnline: boolean
  description: string
  viewDescription: string
  audioEnabled: boolean
  rtsp: string
  archiveDepth: number
  district: string
  objectType: nullable<objectType>
  headPointState?: string
  headPointName?: string
  isPTZ: boolean
  zoomEnable: boolean
}

export type transmitter = {
  cameras: Array<camera>
  createdAt: string
  createdBy: createdBy
  createdByName: string
  createdByLastName: string
  createdByPatronymic: string
  description: string
  district: string
  geoAddress: string
  gps: gps
  maxAmountOfChannels: number
  name: string
  organisationName: string
  serialNumber: string
  status: string
}

export type cameraGroup = {
  id: string
  groupName: string
  cameras: Array<camera>
  organisationName: string
  description: string
  createdAt: string
  createdBy: createdBy
  modifiedAt: string
  modifiedBy: string
}

export type cameraWithoutGroup = Omit<cameraGroup, 'id' | 'organisationName'>

export enum userRolesEnum {
  stp = 'stp',
  vendor = 'vendor',
  admin = 'admin',
  super_admin = 'super_admin',
  user = 'user'
}

export type user = {
  email: string
  firstName: string
  id: string
  lastName: string
  login: string
  organisationName: string
  parentUserId: string
  patronymic: string
  phone: string
  role: userRolesEnum
  createdAt: string
  createdBy: createdBy
  notificationsOn: boolean
  cameraList?: [],
  cameraGroupList?: [],
  rights?: Array<rightsEnum> | null,
  hidden: boolean
}

export type organisation = {
  id: string
  name: string
  type: string
  address: string
  phone: string
  email: string
  isECHD: boolean
  createdAt: string
  createdBy: createdBy
  superAdmin?: Omit<user, 'createdBy'> & {createdBy: string}
  modifiedAt: string
  serviceTypes: Array<serviceType>
}

export enum organisationTypeEnum {
  individual = 'individual',
  entity = 'entity'
}

export enum successDialogType {
  devices = 'devices',
  organisation = 'organisation',
  user = 'user',
  default = 'default',
}

export enum instructionType {
  stp = 'stp',
  user = 'user',
  password = 'password',
}

export enum sortOrderEnum {
  'desc' = 'desc',
  'asc' = 'asc'
}
