import instance from "@/api/http";
import { commentsRequestParams } from "@/types/devices";

export default {
  addComment(sourceId: string, message: string) {
    return instance.post('comments', { sourceId, message })
  },
  getCommentsList(params: commentsRequestParams) {
    return instance.get('comments', { params })
  },
}
