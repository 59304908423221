import { defineStore } from 'pinia'
import { rootTransmittersState } from "@/types/store";
import { transmittersRequestParams } from "@/types/devices";
import api from "@/api/api";
import { useTransmittersFiltersStore } from "@/store/devices/transmittersFilters";
import { useLoadingStore } from "@/store/loading";

export const useTransmittersStore = defineStore('transmitters', {
  state: (): rootTransmittersState => ({
    transmitters: [],
    isError: false
  }),

  actions: {
    getTransmitterList() {
      const loadingStore = useLoadingStore()
      loadingStore.loadingStart()

      const transmittersFiltersStore = useTransmittersFiltersStore()
      const params: transmittersRequestParams = {}

      if (transmittersFiltersStore.organisations.length) {
        params.organisationNames = transmittersFiltersStore.organisations.join()
      }
      if (transmittersFiltersStore.statuses.length) {
        params.statuses = transmittersFiltersStore.statuses.join()
      }

      return api.devices.getTransmitterList(params)
        .then((res) => {
          if (res.data?.data?.items) {
            this.transmitters = res.data.data.items
          }
        })
        .catch(() => {
          this.isError = true
        })
        .finally(() => {
          loadingStore.loadingStop()
        })
    }
  },
})
