import instance from "@/api/http";
import { authResponse } from "@/types/api";

export default {
  login(login: string, password: string, agreementAccepted?: boolean) {
    const data = { login, password, agreementAccepted }
    return instance.post<authResponse>('auth', data)
  },

  logout() {
    return instance.patch('user/logout')
  }
}
