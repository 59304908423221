import { defineStore } from 'pinia'
import { rootUserCreationState } from "@/types/store";
import api from "@/api/api";
import { rightsEnum, userRolesEnum } from "@/types/main";
import { useUserStore } from "@/store/user";
import { userCreationData } from "@/types/users";
import unformatPhone from "@/utils/unformatPhone";

export const useUserCreationStore = defineStore('userCreation', {
  state: (): rootUserCreationState => ({
    creationUserRole: userRolesEnum.user,
    dataDialogActive: false,
    camerasDialogActive: false,

    firstName: '',
    lastName: '',
    patronymic: '',
    organisationName: '',
    login: '',
    password: '',
    email: '',
    phone: '',
    cameraList: [],
    cameraGroupList: [],
    archiveAccess: false
  }),

  actions: {
    createUser() {
      const userStore = useUserStore()

      const payload: userCreationData = {
        role: this.creationUserRole,
        firstName: this.firstName,
        lastName: this.lastName,
        patronymic: this.patronymic,
        login: this.login,
        email: this.email,
        phone: unformatPhone(this.phone),
      }

      if (this.password) {
        payload.password = this.password
      }

      if (this.isOrganisationRequiredToEnter) {
        payload.organisationName = this.organisationName
      } else if (userStore.isAdmin || userStore.isSuperAdmin) {
        payload.organisationName = userStore.userOrganisation
      }

      if (this.creationUserRole === userRolesEnum.user) {
        payload.cameraList = this.cameraList
        payload.cameraGroupList = this.cameraGroupList
        payload.rights = this.archiveAccess ? [rightsEnum.archive_access] : []
      }
      return api.users.createUser(payload)
    },
    resetUserData() {
      this.firstName = ''
      this.lastName = ''
      this.patronymic = ''
      this.organisationName = ''
      this.login = ''
      this.password = ''
      this.email = ''
      this.phone = ''
      this.cameraList = []
      this.cameraGroupList = []
      this.archiveAccess = false
    },
  },

  getters: {
    isOrganisationRequiredToEnter(): boolean {
      const userStore = useUserStore()

      const isSuperAdminRole = this.creationUserRole === userRolesEnum.super_admin
      const isUserRole = this.creationUserRole === userRolesEnum.user
      const isAdminRole = this.creationUserRole === userRolesEnum.admin
      const isVendorRole = this.creationUserRole === userRolesEnum.vendor

      return (userStore.isStp && (isAdminRole || isUserRole || isSuperAdminRole || isVendorRole))
          || (userStore.isVendor && isSuperAdminRole)
    }
  }
})
