const arrayLength = 5

function fillArrayByLevel(value: string, phase: number, arrayResult: Array<Array<string>>) {
  for (let i = 0; i < value.length; i++) {
    if (i >= phase * arrayLength && i < (phase + 1) * arrayLength) {
      if (!arrayResult[phase]) {
        arrayResult.push([value[i]])
      } else {
        arrayResult[phase].push(value[i])
      }
    }
  }
}
// заполнят массив по слоям

function getMatrix(value: string) {
  const arrayResult: Array<Array<string>> = []

  if (Number.isNaN(+value)) {
    return arrayResult
  }

  const count = Math.ceil(value.length / arrayLength)

  for (let i = 0; i < count; i++) {
    fillArrayByLevel(value, i, arrayResult)
  }
  return arrayResult
}
// вернет матрицу на основе числа в двоичном формате

export default (value: string) => {
  let newValue = value
  const zerosCount = newValue.replaceAll('1', '')
  const onesCount = newValue.replaceAll('0', '')

  const isSpecialSymbol = zerosCount.length > onesCount.length

  if (isSpecialSymbol) {
    newValue = newValue
      .replaceAll('1', 'a')
      .replaceAll('0', '1')
      .replaceAll('a', '0')
  }

  return {
    matrix: getMatrix(newValue),
    isSpecialSymbol
  }
}
