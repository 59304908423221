export default <T extends (...args: any[]) => any>(callback: T, waitFor: number) => {
  let timeout: number | ReturnType<typeof setTimeout> = 0;
  return (...args: Parameters<T>): ReturnType<T> => {
    let result: any;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      result = callback(...args);
    }, waitFor);
    return result;
  };
}
