import { defineStore } from 'pinia'
import api from "@/api/api";
import { rootCameraSettingsState } from "@/types/store";
import {
  actionEnum,
  allSettingsRequestParams, categoryEnum, deviceType, optionsType,
  settingsRequestParams
} from "@/types/cameraSettings";

export const useCameraSettingsStore = defineStore('cameraSettings', {
  state: (): rootCameraSettingsState => ({
    paramsStreamDialogActive: false,
    resetSettingsDialogActive: false,
    rebootSettingsDialogActive: false,
    editSettingsDialogActive: false,

    category: '' as categoryEnum,
    cameraId: '',
    camerasGroupId: '',
    camerasOptions: [],
    camerasGroupsOptions: []
  }),

  actions: {
    sendCommand(params: settingsRequestParams) {
      return api.cameraSettings.sendCommand(params)
    },
    sendCommandToAllCameras(params: allSettingsRequestParams) {
      return api.cameraSettings.sendCommandToAllCameras(params)
    },
    getOptions(id: string) {
      return api.cameraSettings.getOptions(id)
    },
    changeOptions(type: deviceType, id: string, options: optionsType) {
      return this.sendCommand({
        type,
        ids: [id],
        command: {
          action: actionEnum.change_options,
          options
        }
      })
    },
    changeOptionsForAllCameras(options: optionsType) {
      return this.sendCommandToAllCameras({
        action: actionEnum.change_options,
        options
      })
    }
  }
})
