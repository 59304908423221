import { organisationTypeEnum, userRolesEnum } from "@/types/main";

export enum usersTabEnum {
  users = 'Пользователи',
  organisations = 'Организации'
}

export enum userSidebarType {
  user,
  organisation
}

export type usersRequestParams = {
  limit?: number
  offset?: number
  organisationNames?: string,
  roles?: string
  search?: string
}

export type notificationsRequestParams = {
  limit?: number
  offset?: number
  search?: string
}

export type organisationsRequestParams = {
  limit?: number
  offset?: number
  search?: string
}

export type userCreationData = {
  role: userRolesEnum
  firstName: string
  lastName: string
  patronymic: string
  phone: string
  organisationName?: string
  login: string
  password?: string
  email: string
  cameraList?: Array<string>
  cameraGroupList?: Array<string>
  rights?: Array<string>
}

export type userUpdateData = {
  userId: string,
  firstName?: string
  lastName?: string
  patronymic?: string
  email?: string,
  phone?: string
  cameraList?: Array<string>
  cameraGroupList?: Array<string>
  rights?: Array<string>
  role?: string
}

export type organisationCreationData = {
  name: string
  address: string
  email: string
  phone: string
  type: organisationTypeEnum
  serviceTypeIds?: Array<number>
}

export type organisationUpdateData = {
  id: string
  phone?: string
  address?: string
  serviceTypeIds?: Array<number>
}
