<template>
  <Dialog
    v-model="active"
    :width="500"
    class="dialog"
  >
    <h2 class="dialog__title" :data-theme="themeStore.getTheme">Введите данные новой организации</h2>
    <PnpInput
      :model-value="name"
      placeholder="Название"
      class="dialog__input"
      @input="emit('update:name', $event)"
      clearable
      id="name-input"
    />
    <PnpInput
      :model-value="address"
      placeholder="Адрес"
      class="dialog__input"
      @input="emit('update:address', $event)"
      clearable
      id="address-input"
    />
    <PnpInput
      :model-value="email"
      placeholder="E-mail"
      class="dialog__input"
      @input="emit('update:email', $event)"
      :error="emailError"
      clearable
      id="email-input"
    />
    <PnpInput
      :model-value="phone"
      placeholder="Телефон"
      class="dialog__input"
      @input="emit('update:phone', $event)"
      :error="phoneError"
      clearable
      mask="+7(###)###-##-##"
      id="phone-input"
    />
    <ServiceTypesSelector
      v-model="serviceTypes"
      class="dialog__input"
      multiple
    />
    <el-button
      type="primary"
      style="width: 100%"
      @click="emit('confirm')"
      :disabled="confirmDisabled"
      :loading="loading"
      :data-theme="themeStore.getTheme"
      id="create-org-btn"
    >
      <template #loading>
        <Spinner color="red" small style="margin-right: 8px"/>
      </template>
      Создать
    </el-button>
  </Dialog>
</template>

<script setup>
import { computed, defineProps, defineEmits } from "vue";
import { useThemeStore } from "@/store/theme";
import emailCheck from "@/utils/emailCheck";
import phoneCheck from "@/utils/phoneCheck";
import ServiceTypesSelector from "@/components/app-base/ServiceTypesSelector.vue";

const themeStore = useThemeStore()

const props = defineProps({
  modelValue: Boolean,
  loading: Boolean,
  name: String,
  address: String,
  email: String,
  phone: String,
  type: String,
  serviceTypes: Array,
})
const emit = defineEmits([
  'update:modelValue', 'confirm', 'update:name',
  'update:address', 'update:email', 'update:phone', 'update:serviceTypes'
])

const active = computed({
  get: () => props.modelValue,
  set: (val) => emit("update:modelValue", val)
})

const serviceTypes = computed({
  get: () => props.serviceTypes,
  set: (val) => emit("update:serviceTypes", val)
})

const emailError = computed(() => !emailCheck(props.email))
const phoneError = computed(() => !phoneCheck(props.phone))

const confirmDisabled = computed(() => {
  return !props.name || !props.address || !props.email || !props.phone || emailError.value || phoneError.value
})
</script>

<style scoped lang="scss">
.dialog {
  &__title {
    color: var(--text-color);
    font-size: 20px;
    text-align: center;
    margin-bottom: 30px;
  }
  &__input {
    margin-bottom: 16px;
  }
}
.dialog {
  &__title[data-theme='dark'] {
    color: var(--white);
  }
}
</style>
