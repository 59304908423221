import { defineStore } from 'pinia'
import { rootCamerasState } from "@/types/store";
import { cameraRequestParams, cameraTypeEnum, } from "@/types/devices";
import api from "@/api/api";
import { useCamerasFiltersStore } from "@/store/devices/camerasFilters";
import { useLoadingStore } from "@/store/loading";

export const useCamerasStore = defineStore('cameras', {
  state: (): rootCamerasState => ({
    cameras: [],
    allCameras: [],
    isError: true,
    currentPage: 1,
    total: 0,
    pageSize: 40,
  }),

  actions: {
    getCameraList() {
      const loadingStore = useLoadingStore()
      loadingStore.loadingStart()

      const camerasFiltersStore = useCamerasFiltersStore()
      const params: cameraRequestParams = {
        limit: this.pageSize,
        offset: (this.currentPage - 1) * this.pageSize,
        cameraTypes: [cameraTypeEnum.pnp_rtsp_cam, cameraTypeEnum.pnp_cam, cameraTypeEnum.pnp_lan_cam].join()
      }

      if (camerasFiltersStore.organisations.length) {
        params.organisationNames = camerasFiltersStore.organisations.join()
      }
      if (camerasFiltersStore.statuses.length) {
        params.status = camerasFiltersStore.statuses.join()
      }
      if (camerasFiltersStore.echdStates.length) {
        params.echdStates = camerasFiltersStore.echdStates.join()
      }
      if (camerasFiltersStore.connections.length) {
        params.connection = camerasFiltersStore.connections.join()
      }
      if (camerasFiltersStore.searchValue) {
        params.search = camerasFiltersStore.searchValue
      }

      return api.devices.getCameraList(params)
        .then((res) => {
          if (res.data?.data?.items) {
            this.cameras = res.data.data.items
            this.total = res.data.data.count
          }
        })
        .catch(() => {
          this.isError = true
        })
        .finally(() => {
          loadingStore.loadingStop()
        })
    },
    getAllCameraList() {
      const loadingStore = useLoadingStore()
      const camerasFiltersStore = useCamerasFiltersStore()
      loadingStore.loadingStart()

      const params: cameraRequestParams = {}

      if (camerasFiltersStore.searchValue) {
        params.search = camerasFiltersStore.searchValue
      }
      return api.devices.getCameraList(params)
        .then((res) => {
          if (res.data?.data?.items) {
            this.allCameras = res.data.data.items
          }
        })
        .catch(() => {
          this.isError = true
        })
        .finally(() => {
          loadingStore.loadingStop()
        })
    },
    changeOnlineStatus(id: string, status: boolean) {
      const listCamera = this.cameras.find((c) => c.id === id)
      if (listCamera) {
        listCamera.isOnline = status
      }
      const mapCamera = this.allCameras.find((c) => c.id === id)
      if (mapCamera) {
        mapCamera.isOnline = status
      }
    }
  },
})
