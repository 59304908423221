import instance from "@/api/http";
import { layoutsResponse } from "@/types/api";

export default {
  createLayout(layoutName: string, description: string) {
    return instance.post('layouts', { layoutName, description })
  },
  deleteLayout(id: string) {
    return instance.delete(`layouts/${id}`)
  },
  getLayoutsList() {
    return instance.get('layouts')
  },
  copyToDefaultLayout(id: string) {
    return instance.patch(`layouts/copy/${id}`)
  },
  clearLayout() {
    return instance.delete(`layouts/clear`)
  },
  addCamera(layoutId: string, cameraId: string, position?: number) {
    return instance.post('layouts/camera', { layoutId, cameraId, position })
  },
  deleteCamera(cameraId: string) {
    return instance.delete(`layouts/camera/${cameraId}`)
  },
  getLayoutCameras(layoutId: string, positionFrom: number, positionTo: number) {
    const params = { layoutId, positionFrom, positionTo }
    return instance.get<layoutsResponse>('layouts/camera', { params })
  },
  updatePositionCamera(layoutId: string, cameraId: string, position: number) {
    return instance.patch('layouts/camera', { layoutId, cameraId, position })
  },
}
